@import url(https://fonts.googleapis.com/css2?family=Khula:wght@300;400;600;700;800&family=Montserrat:ital,wght@0,300;0,400;0,600;0,800;0,900;1,400&display=swap);
/* Google Fonts Import */

html {
  background-color: #292672;
  background-image: url(/static/media/bkg_flowers_blue.197882ea.jpg);
  background-repeat: repeat;
  background-size: 600px;
}

* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: 'Khula', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-size: 14px;
  line-height: 1.65;
  letter-spacing: 0.5px;
  font-weight: 600;
  margin: 0 0 20px;
}

p:last-child {
  margin-bottom: 0;
}

b, strong {
  font-weight: 700;
}

a {
  font-family: 'Montserrat';
  font-size: 13px;
  text-decoration: none;
  font-weight: 600;
  color: #EA4388;
}

a:hover,
a:active {
  border-bottom: 2px dotted;
}

sup {
  vertical-align: text-top;
  color: #415CAD;
  font-weight: 700;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

@media screen and (min-width: 768px) {
  p {
    font-size: 15px;
  }
  a {
    font-size: 14px;
  }
}

@media screen and (min-width: 1200px) {
  p {
    font-size: 17px;
    line-height: 1.75;
    margin-bottom: 25px;
  }
  a {
    font-size: 16px;
  }
}

@media screen and (min-width: 1920px) {
  p {
    font-size: 16px;
    line-height: 1.75;
    margin-bottom: 25px;
  }
  a {
    font-size: 15px;
  }
}
